import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  SmsTrafficConversionCodesDropdownStrategy
} from '../../../../shared/common/enum-dropdown/strategies/sms-traffic-conversion-codes.dropdown-strategy';
import {
  MtsCommunicatorConversionCodesDropdownStrategy
} from '../../../../shared/common/enum-dropdown/strategies/mts-communicator-conversion-codes.dropdown-strategy';
import {GaMessagingService, isNullOrUndefined} from '@koddington/ga-common';
import {filter, first} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {SentMessageStateViewModel} from './models/sent-message-state.view-model';
import { WlMockSmsDetails } from 'src/app/services/autogen/SmsGate';
import { MockSentSmsService } from 'src/app/services/autogen/SmsGate';
import { WlMockSmsState } from 'src/app/services/autogen/SmsGate';
import { WlSmsErrorCodesExtensions } from 'src/app/services/autogen/Shared';
import {WlSmsChannels, WlSmsChannelsExtensions} from 'src/app/services/autogen/Shared';

@UntilDestroy()
@Component({
  selector: 'app-sent-message-info',
  templateUrl: './sent-message-info.component.html',
  styleUrls: ['./sent-message-info.component.css']
})
export class SentMessageInfoComponent implements OnInit, OnDestroy {

  @Input()
  public sms: WlMockSmsDetails;

  public viewModel: SentMessageStateViewModel;
  public sentCodes = WlSmsErrorCodesExtensions;
  public channels = WlSmsChannelsExtensions;

  constructor(public readonly smsTrafficConversionCodeStrategy: SmsTrafficConversionCodesDropdownStrategy,
              public readonly mtsCommunicatorConversionCodeStrategy: MtsCommunicatorConversionCodesDropdownStrategy,
              private readonly _apiClient: MockSentSmsService,
              private readonly _messages: GaMessagingService) {
  }

  public get canSetMtsConversion(): boolean {
    return this.viewModel.account.type === WlSmsChannels.MtsCommunicator;
  }

  public get canSetSmsTrafficConversion(): boolean {
    return this.viewModel.account.type === WlSmsChannels.SmsTraffic;
  }

  public get loading(): boolean {
    return isNullOrUndefined(this.viewModel);
  }

  public ngOnInit(): void {
    this.viewModel = new SentMessageStateViewModel(this.sms);
  }

  public ngOnDestroy(): void {
    this.viewModel = null;
  }

  public saveChanges(): void {
    const form = new WlMockSmsState();
    form.smsId = this.viewModel.id;
    form.smsTrafficConversionCode = this.viewModel.smsTrafficConversionCode.strictValue;
    form.mtsCommunicatorConversionCode = this.viewModel.mtsCommunicatorConversionCode.strictValue;

    this._apiClient.updateSmsStates([form])
      .pipe(
        first(),
        filter(u => !this._messages.tryShowError(u)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this._messages.showMessage('Изменения сохранены');
      });
  }
}
