import {Observable, of} from 'rxjs';
import {WlBetPlatforms, WlBetPlatformsExtensions} from 'src/app/services/autogen/Shared';
import {IDropdownStrategy} from './i-dropdown-strategy';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import {Injectable} from '@angular/core';

@Injectable()
export class BetPlatformsStrategy implements IDropdownStrategy<WlBetPlatforms> {
  private collection = [
    WlBetPlatforms.WebRu,
    WlBetPlatforms.CheckinPPS,
    WlBetPlatforms.IOS,
    WlBetPlatforms.Android,
    WlBetPlatforms.MobileRU,
    WlBetPlatforms.NewWebRu,
  ];

  constructor() {
  }

  public getEntities(): Observable<Array<WlBetPlatforms>> {
    return of(this.collection);
  }

  public convert(model: WlBetPlatforms): DropdownItem<WlBetPlatforms> {
    return {
      entity: model,
      title: WlBetPlatformsExtensions.format(model),
      id: Number(model).toString()
    };
  }
}
